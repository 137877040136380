import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  formatDate,
  formatParametersPresetsData,
  getTestID,
} from '../../utils/utils';
import { routes } from '../../utils/constants';
import SecondsAsEstimatedTime from '../common/SecondsAsEstimatedTime';
import Back from '../ui/Back';
import Error from '../ui/Error';
import GeberitSpinner from '../ui/GeberitSpinner';
import Alert from '../ui/Alert';
import Tag from '../ui/Tag';
import Collaborators from './Collaborators';
import EllipsisMenu from './EllipsisMenu';
import RevisionTable from '../CatalogueList/RevisionTable/RevisionTable';
import {
  useCatalogue, useConfiguration, useRequestCheck, useTransformation,
} from '../../api/hooks';
import KeyValuePairs from '../ui/KeyValuePairs';
import RevisionTableMobile from '../CatalogueList/RevisionTable/RevisionTableMobile';

function HeadingSection({
  title, toggleVisibility, isVisible,
  subtitle = '', tags = [],
}) {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="space-between" marginBottom="15px">
      <Flex gap="2" alignItems="center">
        <Heading margin={0} alignSelf="center">
          {title}
          &nbsp;
          <Text as="span" fontWeight="normal">
            {subtitle}
          </Text>
        </Heading>
        {tags.map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </Flex>
      <Button variant="link" alignSelf="center" onClick={toggleVisibility}>
        {isVisible ? t('hide') : t('show')}
      </Button>
    </Flex>
  );
}

HeadingSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  toggleVisibility: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

function AlertMessage({
  condition, variant, message,
  link = null,
}) {
  return condition && (
    <Alert variant={variant}>
      <>
        {message}
        {link && <Link href={link.href}>{link.text}</Link>}
      </>
    </Alert>
  );
}

AlertMessage.propTypes = {
  condition: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
  }),
};

function CollapsibleSection({ isVisible, items }) {
  return (
    <Collapse in={isVisible}>
      <KeyValuePairs list={items} />
      <Divider />
    </Collapse>
  );
}

CollapsibleSection.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

function Catalogue() {
  const [presetsVisible, setPresetsVisible] = useState(false);
  const [parametersVisible, setParametersVisible] = useState(true);

  const { t } = useTranslation();
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: catalogue, loading, error } = useCatalogue(uuid);
  const { data: requestCheck } = useRequestCheck(uuid);
  const { data: transformation } = useTransformation(
    catalogue?.exchangeStandard,
    !!(requestCheck && !requestCheck.isExchangeStandardDeprecated),
  );
  const { data: configuration } = useConfiguration(uuid, requestCheck);

  const tableView = useBreakpointValue({
    base: 'mobile',
    md: 'desktop',
  });

  const isCatalogueArchived = catalogue?.statuses?.includes('archived');
  const isTransformationDeprecated = !isCatalogueArchived
    && requestCheck?.isExchangeStandardDeprecated;
  const isRevisionForbidden = !isCatalogueArchived
    && (!requestCheck?.isExchangeStandardDeprecated && !requestCheck?.canCreateRevisionFlag);

  const [mergedCatalogues, setMergedCatalogues] = useState({});

  useEffect(() => {
    setMergedCatalogues({
      ...catalogue,
      parameters: configuration?.parameters,
      presets: configuration?.presets,
    });
  }, [configuration, catalogue]);

  const parameterKeyValues = catalogue?.parameters?.map((param) => ({
    key: t(`parameters.${param.name}.label`),
    value: formatParametersPresetsData(param, t('notSpecified')),
  }));

  const presetKeyValues = catalogue?.presets?.map((preset) => ({
    key: t(`presets.${catalogue.exchangeStandard}.${preset.name}.label`),
    value: formatParametersPresetsData(preset, t('notSpecified')),
  }));

  const catalogueInfoValuePairs = [
    { key: t('catalogueOwner'), value: catalogue?.requestedBy?.fullName },
    { key: t('createdOn'), value: formatDate(catalogue?.createdOn) },
  ];

  return (
    <Box data-testid={getTestID('Catalogue')}>
      <Back to="/" text={t('backToOverview')} />
      {loading && <GeberitSpinner />}
      {catalogue && (
        <>
          <Flex justifyContent="space-between">
            <Heading as="h1" size="h1">{catalogue?.name}</Heading>
            <Flex>
              <EllipsisMenu
                catalogue={mergedCatalogues || {}}
                transformation={transformation || {}}
              />
            </Flex>
          </Flex>
          <SecondsAsEstimatedTime totalSeconds={catalogue?.averageTimeTakenSec} />
          <Box flex={{ base: '0 0 100%', md: '0 0 60%' }}>
            <KeyValuePairs list={catalogueInfoValuePairs} templateColumns={{ base: '1fr' }} gridGap="4" />
          </Box>
          <Text data-testid={getTestID('DescriptionText')} marginBottom="8">{catalogue?.description}</Text>
          <AlertMessage
            condition={isCatalogueArchived}
            variant="warning"
            message={`${t('catalogue.isArchived')} `}
          />
          <AlertMessage
            condition={requestCheck && isTransformationDeprecated}
            variant="warning"
            message={`${t('catalogue.exchangeStandardNotSupported')} `}
            link={{ href: routes.TRANSFORMATIONS, text: t('catalogue.pleaseCreateNew') }}
          />
          <AlertMessage
            condition={requestCheck && isRevisionForbidden}
            variant="warning"
            message={`${t('catalogue.cannotCreateRevision')} `}
            link={{
              href: routes.CLONE_CATALOGUE,
              text: t('catalogue.pleaseDuplicate'),
              onClick: () => navigate(routes.CLONE_CATALOGUE, {
                state: { catalogue: mergedCatalogues, transformation },
              }),
            }}
          />
          <Divider />
          {catalogue?.parameters?.length > 0 && (
            <>
              <HeadingSection
                title={t('inputParameters')}
                subtitle={t(`transformations.${catalogue?.exchangeStandard}.pretty_name`)}
                tags={[
                  isTransformationDeprecated && t('catalogue.deprecated'),
                  isCatalogueArchived && t('archived'),
                ].filter(Boolean)}
                toggleVisibility={() => setParametersVisible(!parametersVisible)}
                isVisible={parametersVisible}
              />
              <CollapsibleSection isVisible={parametersVisible} items={parameterKeyValues} />
            </>
          )}
          {catalogue?.presets?.length > 0 && (
            <>
              <HeadingSection
                title={t('transformationPresets')}
                toggleVisibility={() => setPresetsVisible(!presetsVisible)}
                isVisible={presetsVisible}
              />
              <CollapsibleSection isVisible={presetsVisible} items={presetKeyValues} />
            </>
          )}
          <Flex justifyContent="space-between" alignItems="center" fontWeight="700" marginBottom="8">
            {tableView !== 'mobile' && (
              <>
                <Heading margin={0}>
                  {t('revisions.name')}
                &nbsp;
                  {catalogue?.revisions && <Text as="span">{`(${catalogue?.revisions.length})`}</Text>}
                </Heading>
                <Button
                  variant="primary"
                  onClick={() => navigate(`/create-revision?parentUuid=${uuid}`)}
                  isDisabled={isCatalogueArchived
                  || isRevisionForbidden
                  || isTransformationDeprecated}
                >
                  <AddIcon marginRight="1" />
                  <Text as="span">{t('revision.create')}</Text>
                </Button>
              </>
            )}
          </Flex>
          {
            tableView === 'mobile' ? (
              <Flex justifyContent="center" direction="column">
                <Heading margin={0}>
                  {t('revisions.name')}
                  &nbsp;
                  {catalogue?.revisions && <Text as="span">{`(${catalogue?.revisions.length})`}</Text>}
                </Heading>
                <RevisionTableMobile catalogue={catalogue} revisions={catalogue?.revisions} />
                <Button
                  variant="primary"
                  onClick={() => navigate(`/create-revision?parentUuid=${uuid}`)}
                  isDisabled={isCatalogueArchived
                    || isRevisionForbidden
                    || isTransformationDeprecated}
                  marginTop="8"
                >
                  <AddIcon marginRight="1" />
                  <Text as="span">{t('revision.create')}</Text>
                </Button>
              </Flex>
            ) : (
              <RevisionTable catalogue={catalogue} revisions={catalogue?.revisions} />
            )
          }
          <Divider />
          <Collaborators catalogue={catalogue} />
        </>
      )}
      {!catalogue && !loading && !uuid && (
        <>
          <Heading as="h1" size="h1">{t('catalogue.notFound')}</Heading>
          <Text>{t('catalogue.notFoundBlurb')}</Text>
        </>
      )}
      {error && <Error error={error} />}
    </Box>
  );
}

export default Catalogue;
